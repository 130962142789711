import React, { useState, useEffect } from 'react';
import { getResponseSettings, getCategoryResponseSettings, updateResponseSettings, updateCategoryResponseSettings, clearAllCategoryResponseSettings } from '../../services/adminApi';
import DOMPurify from 'dompurify';
import { ResponseSettingsData, ResponseSettingsProps, CategoryResponseSettings } from '../../types/settings';
import { useToast } from '../../context/ToastContext';

const defaultSettings: ResponseSettingsData = {
  responseLength: '',
  structurePreferences: [],
  proposeMeetings: false,
  meetingTimeframe: '',
  includeCalendarLink: false,
  greetingOptions: [],
  closingOptions: [],
  signature: '',
  categories: []
};

const ResponseSettings: React.FC<ResponseSettingsProps> = ({ userId }) => {
  const [settings, setSettings] = useState<ResponseSettingsData>(defaultSettings);
  const [categorySettings, setCategorySettings] = useState<CategoryResponseSettings[]>([]);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showToast } = useToast();

  const responseLengthOptions = ['Very Brief (1 paragraph)', 'Brief (2-3 paragraphs)', 'Moderate (4-5 paragraphs)', 'Detailed (6+ paragraphs)'];
  const structureOptions = ['Use bullet points', 'Use numbered lists', 'Use paragraphs only'];
  const meetingTimeframeOptions = ['Today', 'Tomorrow', 'This week', 'Next week', 'This month', 'Custom'];

  const normalizeData = (data: any): ResponseSettingsData => {
    return {
      responseLength: data.response_length || '',
      structurePreferences: data.structure_preferences || [],
      proposeMeetings: data.propose_meetings || false,
      meetingTimeframe: data.meeting_timeframe || '',
      includeCalendarLink: data.include_calendar_link || false,
      greetingOptions: data.greeting_options || [],
      closingOptions: data.closing_options || [],
      signature: data.signature || '',
      categories: data.categories || []
    };
  };

  const removeDuplicateCategories = (categories: CategoryResponseSettings[]): CategoryResponseSettings[] => {
    const uniqueCategories = new Map();
    categories.forEach(category => {
      if (!uniqueCategories.has(category.category)) {
        uniqueCategories.set(category.category, category);
      }
    });
    return Array.from(uniqueCategories.values());
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        const [generalData, categoryData] = await Promise.all([
          getResponseSettings(userId),
          getCategoryResponseSettings(userId)
        ]);

        const normalizedGeneralData = normalizeData(generalData);
        setSettings(normalizedGeneralData);

        const uniqueCategoryData = removeDuplicateCategories(categoryData);
        setCategorySettings(uniqueCategoryData);

        setError(null);
        showToast('Response settings loaded successfully', 'success');
      } catch (err) {
        console.error('Error fetching response settings:', err);
        setError('Failed to fetch response settings. Please try again later.');
        setSettings(defaultSettings);
        showToast('Failed to load response settings', 'error');
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, [userId, showToast]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (categoryName: string, field: keyof CategoryResponseSettings, value: string) => {
    setCategorySettings(prev =>
      prev.map(category =>
        category.category === categoryName
          ? { ...category, [field]: value }
          : category
      )
    );
  };
  

  const handleCategorySubmit = async (categoryName: string) => {
    const categoryData = categorySettings.find(c => c.category === categoryName);
    if (categoryData) {
      try {
        setIsLoading(true);
        await updateCategoryResponseSettings(userId, categoryName, categoryData);
        setError(null);
        showToast(`Category settings for "${categoryName}" updated successfully`, 'success');
      } catch (err) {
        console.error('Error updating category response settings:', err);
        setError('Failed to update category response settings.');
        showToast(`Failed to update category settings for "${categoryName}"`, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };
  

  const toggleCategory = (categoryName: string) => {
    setExpandedCategory(expandedCategory === categoryName ? null : categoryName);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload = {
      user_id: userId,
      response_length: settings.responseLength,
      structure_preferences: settings.structurePreferences,
      greeting_options: settings.greetingOptions,
      closing_options: settings.closingOptions,
      signature: settings.signature,
      propose_meetings: settings.proposeMeetings,
      meeting_timeframe: settings.meetingTimeframe,
      include_calendar_link: settings.includeCalendarLink,
    };
    try {
      setIsLoading(true);
      await updateResponseSettings(userId, payload);
      setError(null);
      showToast('Response settings updated successfully', 'success');
    } catch (err) {
      console.error('Error updating response settings:', err);
      setError('Failed to update response settings. Please try again.');
      showToast('Failed to update response settings', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStructurePreferenceChange = (preference: string) => {
    setSettings((prev) => {
      const updatedPreferences = prev.structurePreferences.includes(preference)
        ? prev.structurePreferences.filter(p => p !== preference) 
        : [...prev.structurePreferences, preference]; 
      return { ...prev, structurePreferences: updatedPreferences };
    });
    showToast(`Structure preference "${preference}" ${settings.structurePreferences.includes(preference) ? 'removed' : 'added'}`, 'info');
  };

  const handleClearAllCategorySettings = async () => {
    if (window.confirm("Are you sure you want to clear all category settings? This action cannot be undone.")) {
      try {
        setIsLoading(true);
        await clearAllCategoryResponseSettings(userId);
        // After clearing, refresh the category settings
        const updatedCategoryData = await getCategoryResponseSettings(userId);
        const uniqueCategoryData = removeDuplicateCategories(updatedCategoryData);
        setCategorySettings(uniqueCategoryData);
        showToast('All category settings cleared successfully', 'success');
      } catch (err) {
        console.error('Error clearing category response settings:', err);
        showToast('Failed to clear category response settings', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };
  

  if (isLoading) return <div className="text-center py-8">Loading...</div>;

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-gray-800">Response Settings</h2>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Category-specific settings */}
        {categorySettings.length > 0 && (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800">Category-Specific Settings</h3>
            {categorySettings.map((category) => (
              <div key={category.category} className="border border-gray-200 rounded-md overflow-hidden">
                <div
                  className="bg-gray-50 px-4 py-3 cursor-pointer flex justify-between items-center"
                  onClick={() => toggleCategory(category.category)}
                >
                  <h4 className="text-lg font-medium text-gray-700">{category.category}</h4>
                  <span>{expandedCategory === category.category ? '−' : '+'}</span>
                </div>
                {expandedCategory === category.category && (
                  <div className="p-4 space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Tone and Style</label>
                        <select
                          value={category.tone_style || ''}
                          onChange={(e) => handleCategoryChange(category.category, 'tone_style', e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="">Select tone</option>
                          <option value="Formal and concise">Formal and concise</option>
                          <option value="Casual and friendly">Casual and friendly</option>
                          <option value="Friendly and enthusiastic">Friendly and enthusiastic</option>
                          <option value="Collaborative and innovative">Collaborative and innovative</option>
                          <option value="Persuasive and passionate">Persuasive and passionate</option>
                          <option value="Assertive and respectful">Assertive and respectful</option>
                          <option value="Empathetic and supportive">Empathetic and supportive</option>
                          <option value="Diplomatic and firm">Diplomatic and firm</option>
                          <option value="Confident and humble">Confident and humble</option>
                          <option value="Supportive and practical">Supportive and practical</option>
                          <option value="Direct and considerate">Direct and considerate</option>
                          <option value="Optimistic and realistic">Optimistic and realistic</option>
                          <option value="Analytical and insightful">Analytical and insightful</option>
                          <option value="Instructive and patient">Instructive and patient</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Jargon Usage</label>
                        <select
                          value={category.jargon_usage || ''}
                          onChange={(e) => handleCategoryChange(category.category, 'jargon_usage', e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="">Select jargon level</option>
                          <option value="none">None</option>
                          <option value="low">Low</option>
                          <option value="moderate">Moderate</option>
                          <option value="high">High</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Example Response</label>
                      <textarea
                        value={category.example_response || ''}
                        onChange={(e) => handleCategoryChange(category.category, 'example_response', e.target.value)}
                        rows={3}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => handleCategorySubmit(category.category)}
                      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                    >
                      Save {category.category} Settings
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* General Response Settings */}
        <div className="space-y-6">
          <h3 className="text-xl font-semibold text-gray-800">General Response Settings</h3>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Response Length Preference</label>
            <select
              name="responseLength"
              value={settings.responseLength}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select response length</option>
              {responseLengthOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Structure Preferences</label>
            <div className="space-y-2">
              {structureOptions.map(option => (
                <label key={option} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.structurePreferences.includes(option)}
                    onChange={() => handleStructurePreferenceChange(option)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-900">{option}</span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={settings.proposeMeetings}
                onChange={(e) => setSettings(prev => ({ ...prev, proposeMeetings: e.target.checked }))}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-sm text-gray-900">Propose meetings</span>
            </label>
          </div>

          {settings.proposeMeetings && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Meeting Timeframe</label>
              <select
                name="meetingTimeframe"
                value={settings.meetingTimeframe}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select timeframe</option>
                {meetingTimeframeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          )}

{/*
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={settings.includeCalendarLink}
                onChange={(e) => setSettings(prev => ({ ...prev, includeCalendarLink: e.target.checked }))}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-sm text-gray-900">Include calendar link</span>
            </label>
          </div>
*/}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Greeting Options</label>
            <input
              type="text"
              name="greetingOptions"
              value={settings.greetingOptions.join(', ')}
              onChange={(e) => setSettings(prev => ({ ...prev, greetingOptions: e.target.value.split(', ') }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter greeting options, separated by commas"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Closing Options</label>
            <input
              type="text"
              name="closingOptions"
              value={settings.closingOptions.join(', ')}
              onChange={(e) => setSettings(prev => ({ ...prev, closingOptions: e.target.value.split(', ') }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter closing options, separated by commas"
            />
          </div>

          <div>
            <label htmlFor="signature" className="block text-sm font-medium text-gray-700 mb-1">
              Email Signature (HTML)
            </label>
            <textarea
              id="signature"
              name="signature"
              value={settings.signature}
              onChange={handleInputChange}
              rows={6}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter your email signature HTML here"
            />
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">Signature Preview</label>
              <div
                className="mt-1 p-4 border rounded-md bg-white"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings.signature) }}
              />
            </div>
          </div>
        </div>

        <div className="pt-5 border-t border-gray-200">
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={handleClearAllCategorySettings}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300"
            >
              Clear All Category Settings
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
            >
              Save All Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResponseSettings;
// File: frontend/src/components/admin/LogsView.tsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getLogs } from '../../services/adminApi';
import { Log, LogPagination } from '../../types/log';
import debounce from 'lodash/debounce';
import { ArrowPathIcon } from '@heroicons/react/24/solid'; 

const LogsView: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [serviceFilter, setServiceFilter] = useState('');
  const [entryTypeFilter, setEntryTypeFilter] = useState('');

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 20; // Number of logs per page
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const totalPages = Math.ceil(totalLogs / pageSize);

  useEffect(() => {
    // Add 'scrollable-page' class to body when component mounts
    document.body.classList.add('scrollable-page');
    // Remove 'scrollable-page' class from body when component unmounts
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  const fetchLogs = useCallback(async () => {
    try {
      setLoading(true);
      const response: LogPagination = await getLogs({
        page: currentPage,
        page_size: pageSize,
        search: searchTerm,
        level: levelFilter,
        service: serviceFilter,
        entry_type: entryTypeFilter,
      });
      setLogs(response.logs);
      setTotalLogs(response.total);
      setError(null);
    } catch (err) {
      setError('Failed to fetch logs');
      console.error('Error fetching logs:', err);
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize, searchTerm, levelFilter, serviceFilter, entryTypeFilter]);

  const debouncedFetchLogs = useMemo(() => debounce(fetchLogs, 300), [fetchLogs]);

  useEffect(() => {
    debouncedFetchLogs();
    return () => {
      debouncedFetchLogs.cancel();
    };
  }, [debouncedFetchLogs]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleRefresh = () => {
    fetchLogs(); 
  };

  const getLevelBadgeClass = (level: string) => {
    switch (level) {
      case 'INFO':
        return 'badge badge-info';
      case 'WARNING':
        return 'badge badge-warning';
      case 'ERROR':
        return 'badge badge-error';
      default:
        return 'badge';
    }
  };

  return (
    <div className="w-full px-4 py-6">
      <h2 className="text-2xl font-bold mb-6">Logs</h2>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6 w-full">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search logs..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full"
        />

        {/* Level Filter */}
        <select
          value={levelFilter}
          onChange={(e) => setLevelFilter(e.target.value)}
          className="select select-bordered w-full"
        >
          <option value="">All Levels</option>
          <option value="INFO">INFO</option>
          <option value="DEBUG">DEBUG</option>
          <option value="WARNING">WARNING</option>
          <option value="ERROR">ERROR</option>
        </select>

        {/* Service Filter */}
        <select
          value={serviceFilter}
          onChange={(e) => setServiceFilter(e.target.value)}
          className="select select-bordered w-full"
        >
          <option value="">All Services</option>
          <option value="api">API</option>
          <option value="gmail_listener">Gmail Listener</option>
          <option value="Admin_action">Admin Action</option>
          <option value="Email_sent">Email Sent</option>
          {/* Add more service options as needed */}
        </select>

        {/* Entry Type Filter */}
        <select
          value={entryTypeFilter}
          onChange={(e) => setEntryTypeFilter(e.target.value)}
          className="select select-bordered w-full"
        >
          <option value="">All Entry Types</option>
          <option value="toggle_gmail_listener">App Activated/Deactivated</option>
          <option value="frontend_request">Frontend Request</option>
          <option value="background_task">Background Task</option>
          <option value="create_user">Create User</option>
          <option value="update_user">Update User</option>
          <option value="delete_user">Delete User</option>
          <option value="process_email_batch">Process Email Batch</option>
          <option value="process_emails">Process Emails</option>
          <option value="check_new_emails">Check New Emails</option>
        </select>

        {/* Refresh Button */}
        <button
          onClick={handleRefresh}
          className="btn btn-primary w-full md:w-auto bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
          aria-label="Refresh Logs"
        >
          <ArrowPathIcon className="h-5 w-5 mr-2" />
          Refresh
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : error ? (
        <div className="alert alert-error mb-6">
          <div className="flex-1">
            <label>{error}</label>
          </div>
        </div>
      ) : logs.length === 0 ? (
        <div className="text-center py-8">
          <span className="text-gray-500">No logs found.</span>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table table-compact w-full">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Level</th>
                  <th>Service</th>
                  <th>Message</th>
                  <th className="hidden md:table-cell">Entry Type</th>
                  <th className="hidden md:table-cell">User</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log.id} className="hover">
                    <td>{new Date(log.timestamp).toLocaleString()}</td>
                    <td>
                      <span className={getLevelBadgeClass(log.level)}>{log.level}</span>
                    </td>
                    <td>{log.service}</td>
                    <td className="whitespace-normal">{log.message}</td>
                    <td className="hidden md:table-cell">{log.entry_type}</td>
                    <td className="hidden md:table-cell">
                      {log.user_email ? `${log.user_email} (${log.user_type})` : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className={`btn btn-outline ${currentPage === 1 ? 'btn-disabled' : ''}`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-500">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className={`btn btn-outline ${currentPage === totalPages ? 'btn-disabled' : ''}`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LogsView;

import React, { useState, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import PageLayout from '../components/layout/PageLayout';
import { EnvelopeIcon, MapPinIcon, GlobeAltIcon, ClockIcon } from '@heroicons/react/24/outline';
import { submitContactForm } from '../services/api';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

const useContactForm = (initialState: ContactFormData) => {
  const [formData, setFormData] = useState<ContactFormData>(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState<'success' | 'error' | null>(null);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedbackMessage('');
    setFeedbackType(null);

    try {
      await submitContactForm(formData);
      setFeedbackMessage('Thank you for your message. We will get back to you soon!');
      setFeedbackType('success');
      setFormData(initialState);
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage('An error occurred. Please try again.');
      setFeedbackType('error');
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, initialState]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value } as ContactFormData));
  }, []);

  return { formData, isSubmitting, feedbackMessage, feedbackType, handleSubmit, handleInputChange };
};



const ContactPage: React.FC = () => {
  const {
    formData: contactFormData,
    isSubmitting: isContactSubmitting,
    feedbackMessage: contactFeedbackMessage,
    feedbackType: contactFeedbackType,
    handleSubmit: handleContactSubmit,
    handleInputChange: handleContactInputChange
  } = useContactForm({ name: '', email: '', message: '' });

  useEffect(() => {
    document.title = 'Contact Us | Inktuitive Email';
  }, []);

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage: "url('https://cdn.midjourney.com/bf29397f-ff58-4bc1-bbce-5d3d56fe2126/0_0.png')",
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mb-4">
            Get in Touch
          </h1>
          <p className="text-xl max-w-3xl">
            Have questions about Inktuitive Email? We're here to help. Reach out to us, and we'll get back to you as soon as possible.
          </p>
        </div>
      </motion.div>

      {/* Contact Section */}
      <motion.div
        className="flex-grow flex flex-col bg-gradient-to-br from-indigo-50 to-purple-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="flex-grow flex flex-col justify-center py-12 sm:py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <h2 className="text-3xl font-semibold mb-6 text-gray-800">Contact Information</h2>
                <div className="space-y-6">
                  <ContactInfo icon={<EnvelopeIcon className="h-6 w-6" aria-hidden="true" />} text="contact@inktuitive.ai" ariaLabel="Email" />
                  {/*<ContactInfo icon={<PhoneIcon className="h-6 w-6" aria-hidden="true" />} text="+1 (555) 123-4567" ariaLabel="Phone" /> */}
                  <ContactInfo icon={<MapPinIcon className="h-6 w-6" aria-hidden="true" />} text="158 Leinster Road, Merivale, Christchurch, NZ" ariaLabel="Address" />
                  <ContactInfo icon={<GlobeAltIcon className="h-6 w-6" aria-hidden="true" />} text="www.inktuitive.ai" ariaLabel="Website" />
                  <ContactInfo icon={<ClockIcon className="h-6 w-6" aria-hidden="true" />} text="Office Hours: Mon-Fri: 9am-5pm NZT" ariaLabel="Business Hours" />
                </div>
                <div className="mt-8">
                  <h3 className="text-xl font-semibold mb-4 text-gray-800">Connect With Us</h3>
                  <div className="flex space-x-4">
                    {/* Add social media icons and links here */}
                  </div>
                </div>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <h2 className="text-3xl font-semibold mb-6 text-gray-800">Send Us a Message</h2>
                <form onSubmit={handleContactSubmit} className="bg-white p-8 rounded-lg shadow-lg">
                  <FormInput
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    value={contactFormData.name}
                    onChange={handleContactInputChange}
                    required
                  />
                  <FormInput
                    label="Email"
                    id="email"
                    name="email"
                    type="email"
                    value={contactFormData.email}
                    onChange={handleContactInputChange}
                    required
                  />
                  <div className="mb-6">
                    <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      value={contactFormData.message}
                      onChange={handleContactInputChange}
                      rows={4}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md hover:bg-indigo-700 transition duration-300 font-semibold"
                    disabled={isContactSubmitting}
                  >
                    {isContactSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                  {contactFeedbackMessage && (
                    <div className={`mt-4 p-3 rounded-md ${
                      contactFeedbackType === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {contactFeedbackMessage}
                    </div>
                  )}
                </form>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Register Interest CTA */}
      <motion.section
        className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">Stay Updated</h2>
          <p className="text-xl mb-8">
            Be among the first to experience the future of AI-powered email management.
          </p>
          <a
            href="/#register"
            className="inline-block bg-white text-indigo-600 font-bold py-3 px-8 rounded-lg hover:bg-indigo-100 transition duration-300"
          >
            Register Your Interest
          </a>
        </div>
      </motion.section>
    </PageLayout>
  );
};

const ContactInfo: React.FC<{ icon: React.ReactNode; text: string; ariaLabel: string }> = ({ icon, text, ariaLabel }) => (
  <div className="flex items-center space-x-4">
    <div className="text-indigo-500" aria-hidden="true">{icon}</div>
    <span className="text-gray-600" aria-label={ariaLabel}>{text}</span>
  </div>
);

const FormInput: React.FC<{
  label: string;
  id: string;
  name: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}> = ({ label, ...props }) => (
  <div className="mb-4">
    <label htmlFor={props.id} className="block text-gray-700 font-semibold mb-2">{label}</label>
    <input
      {...props}
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
    />
  </div>
);

export default ContactPage;

import React, { useEffect, Suspense, useMemo } from 'react';
import { motion } from 'framer-motion';
import PageLayout from '../components/layout/PageLayout';
import { InboxIcon, EnvelopeIcon, PencilSquareIcon, SparklesIcon, ShieldCheckIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import MintDesignLogo from '../images/logos/Mint Design Full Logo DeepGreen.png';
import HarveyCameronLogo from '../images/logos/HC.png';

const trustedByLogos = [MintDesignLogo, HarveyCameronLogo];

interface Feature {
  name: string;
  description: string;
  icon: React.ReactNode;
  ariaLabel: string;
  imageSrc: string;
}

const FeaturePage: React.FC = () => {
  const features: Feature[] = useMemo(() => [
    {
      name: 'AI-Powered Sorting',
      description: 'Our advanced AI intelligently categorises emails based on content, sender, and urgency, reducing inbox clutter and prioritising what matters, saving you time and mental energy.',
      icon: <SparklesIcon className="h-12 w-12" aria-hidden="true" />,
      ariaLabel: "AI-Powered Sorting feature",
      imageSrc: "https://cdn.midjourney.com/4177ff5c-30ba-4f15-9b7b-e8a40c35973b/0_1.png"
    },
    {
      name: 'Smart Auto-Drafted Replies',
      description: 'Our AI writes personalised, context-aware draft responses based on your communication style and role. This feature can help you respond to emails up to 3 times faster, ensuring efficient communication without compromising on quality or personal touch.',
      icon: <EnvelopeIcon className="h-12 w-12" aria-hidden="true" />,
      ariaLabel: "Smart Auto-Drafted Replies feature",
      imageSrc: "https://cdn.midjourney.com/2057fe4a-db49-45d0-8e3e-3890570662d3/0_1.png"
    },
    {
      name: 'Seamless Background Assistance',
      description: 'Inktuitive works quietly in the background, intelligently managing your inbox without disrupting your workflow. It\'s like having a 24/7 personal email assistant that learns your preferences, suggests actions, and even handles routine correspondence automatically.',
      icon: <InboxIcon className="h-12 w-12" aria-hidden="true" />,
      ariaLabel: "Seamless Background Assistance feature",
      imageSrc: "https://cdn.midjourney.com/16a22790-7553-4b54-9e64-87889a5ebcc7/0_2.png"
    },
    {
      name: 'Customised Response Templates',
      description: 'Create and fine-tune response templates for different email categories and scenarios. Our AI then adapts these templates to each specific email, maintaining your personal touch while saving time.',
      icon: <PencilSquareIcon className="h-12 w-12" aria-hidden="true" />,
      ariaLabel: "Customised Response Templates feature",
      imageSrc: "https://cdn.midjourney.com/e12a8b77-52d7-4770-ad9a-bc87590fdf24/0_3.png"
    },
    {
      name: 'Private & Confidential',
      description: 'Your privacy is our top priority. We employ end-to-end encryption and zero-knowledge architecture, meaning we don\'t store or have access to your email data. All processing happens on your device, ensuring your sensitive information never leaves your control.',
      icon: <ShieldCheckIcon className="h-12 w-12" aria-hidden="true" />,
      ariaLabel: "Private and Confidential feature",
      imageSrc: "https://cdn.midjourney.com/8687752e-78eb-4b53-860c-4c67cd13d696/0_0.png"
    },
  ], []);

  useEffect(() => {
    document.title = 'Features | Inktuitive Email';
  }, []);

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage: "url('https://cdn.midjourney.com/bf29397f-ff58-4bc1-bbce-5d3d56fe2126/0_0.png')",
          }}
        ></div>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            Revolutionize Your Email Experience
          </h1>
          <p className="mt-6 text-xl max-w-3xl">
            Inktuitive Email brings cutting-edge AI technology to your inbox, making email management effortless and boosting your productivity.
          </p>
          <div className="mt-10">
            <a
              href="/#register"
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
            >
              Register Interest
              <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </div>
      </motion.div>

      {/* Features Section */}
      <motion.div
        id="features"
        className="flex-grow flex flex-col bg-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="flex-grow flex flex-col justify-center py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto w-full">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-4xl font-extrabold text-center mb-16 text-gray-800">AI-Powered Features That Understand You</h2>
              <div className="space-y-24">
                {features.map((feature, index) => (
                  <Suspense fallback={<div>Loading...</div>} key={index}>
                    <motion.div
                      className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center bg-gradient-to-br from-white to-indigo-50 p-8 rounded-lg shadow-lg`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <div className="md:w-1/2 mb-6 md:mb-0 md:mx-8">
                        <img src={feature.imageSrc} alt={feature.name} className="rounded-lg shadow-md w-full h-auto object-cover" />
                      </div>
                      <div className="md:w-1/2">
                        <div className="flex items-center mb-4">
                          <div className="text-indigo-500 mr-4">
                            {feature.icon}
                            <span className="sr-only">{feature.ariaLabel}</span>
                          </div>
                          <h3 className="text-3xl font-semibold text-gray-800">{feature.name}</h3>
                        </div>
                        <p className="text-xl text-gray-600">{feature.description}</p>
                      </div>
                    </motion.div>
                  </Suspense>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Trusted By Section */}
      <motion.div
        className="bg-indigo-50 py-16 px-4 sm:py-24 sm:px-6 lg:px-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-extrabold text-center mb-12 text-gray-800">Trusted by These Brands & Agencies</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center">
            {trustedByLogos.map((logo, index) => (
              <img key={index} src={logo} alt={`Trusted brand ${index + 1}`} className="h-12 w-auto object-contain" />
            ))}
          </div>
        </div>
      </motion.div>

      {/* Call to Action */}
      <motion.div
        className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            <span className="block">Ready to transform your email experience?</span>
            <span className="block text-indigo-300">Register your interest today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/#register"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
              >
                Register Interest
              </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </PageLayout>
  );
};

export default FeaturePage;
import React, { useState, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import ROICalculator from '../components/widgets/ROICalculator';
import PageLayout from '../components/layout/PageLayout';
import { EnvelopeIcon, InboxIcon, PencilSquareIcon, SparklesIcon, ClockIcon, BoltIcon, CurrencyDollarIcon, PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { registerInterest } from '../services/api'; 

const LandingPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    
    const timer = setTimeout(() => setIsVisible(true), 500);

    return () => {
      document.body.classList.remove('scrollable-page');
      clearTimeout(timer);
    };
  }, []);

  const handleInterestRegistration = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedbackMessage('');
    setFeedbackType(null);

    try {
      await registerInterest(email);
      setFeedbackMessage('Thank you for your interest! We\'ll be in touch soon.');
      setFeedbackType('success');
      setEmail('');
    } catch (error) {
      console.error('Error registering interest:', error);
      setFeedbackMessage('An error occurred. Please try again.');
      setFeedbackType('error');
    } finally {
      setIsSubmitting(false);
    }
  }, [email]);

  return (
    <PageLayout>
    <div className="min-h-screen">
      <section className="relative flex items-center justify-center" style={{ minHeight: '70vh' }}>
      <div 
        className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('https://cdn.midjourney.com/bf29397f-ff58-4bc1-bbce-5d3d56fe2126/0_0.png')",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-600 to-purple-600 opacity-75"></div>
      </div>
      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight mb-4 text-white">
            Effortless Email Management 
            <br className="hidden sm:inline" />with AI
          </h1>
          <p className="text-lg sm:text-xl text-gray-200 mb-6 sm:mb-8 max-w-3xl mx-auto">
            Inktuitive AI reshapes email management, making it efficient, intuitive, and effortless.
          </p>
          <a href="#register" className="btn btn-primary btn-lg px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg">
            Register Interest for Early Access
          </a>
        </motion.div>
      </div>
    </section>

      <section id="features" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">AI-Powered Features That Understand You</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { 
                name: 'Contextual Understanding', 
                description: 'Your email assistant, designed to adapt to your role and industry, intelligently evaluates each email’s context to ensure accurate categorisation and only drafts responses when necessary, streamlining your workflow.', 
                icon: <SparklesIcon className="h-8 w-8" /> 
              },
              { 
                name: 'Intelligent Sorting', 
                description: 'With advanced AI-driven sorting, your emails are categorised based on context, significantly cutting down clutter. Important messages are prioritised, allowing you to focus on what truly matters and reducing inbox overwhelm.', 
                icon: <InboxIcon className="h-8 w-8" /> 
              },
              { 
                name: 'Smart Auto-Drafted Replies', 
                description: 'Effortlessly handle replies with AI-generated drafts that capture both the content and tone of the conversation. Save time by sending thoughtful responses with just a few clicks.', 
                icon: <EnvelopeIcon className="h-8 w-8" /> 
              },
              { 
                name: 'Customized Response Templates', 
                description: 'Tailor your AI-generated drafts to match your communication style. Whether you’re aiming for a persuasive, assertive, or instructive tone, the AI adapts to your preferences, ensuring every response aligns with your unique voice and audience.', 
                icon: <PencilSquareIcon className="h-8 w-8" /> 
              },
              
            ].map((feature, index) => (
              <motion.div 
                key={index} 
                className="bg-gradient-to-br from-white to-indigo-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center mb-4">
                  <div className="text-indigo-500 mr-4">{feature.icon}</div>
                  <h3 className="text-2xl font-semibold text-gray-800">{feature.name}</h3>
                </div>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section id="research" className="py-20 bg-gradient-to-br from-indigo-50 to-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800">The Impact of Email Management</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <motion.div 
              className="bg-white p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
              transition={{ duration: 0.5 }}
            >
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Time Spent on Emails</h3>
              <p className="text-gray-600">The average worker spends <strong>28% of their workweek</strong> (about 11 hours) on emails, totaling <strong>577 hours annually</strong>.</p>
            </motion.div>
            <motion.div 
              className="bg-white p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Productivity Impact</h3>
              <p className="text-gray-600">Professionals check emails <strong>5-6 times per hour</strong>, with each interruption taking up to 64 seconds to regain focus.</p>
            </motion.div>
          </div>
          <h3 className="text-3xl font-extrabold text-center mb-8 text-gray-800">Potential Benefits of Inktuitive Email</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { 
                title: 'Time-Saving', 
                description: 'Cut down email management time by up to 50%, gaining back 5-6 hours per week.', 
                icon: <ClockIcon className="h-8 w-8" /> 
              },
              { 
                title: 'Increased Focus', 
                description: 'Reduce constant email interruptions, allowing for deeper focus on important tasks.', 
                icon: <BoltIcon className="h-8 w-8" /> 
              },
              { 
                title: 'Cost-Efficient', 
                description: 'Save $7,000 or more per employee annually by automating email tasks.', 
                icon: <CurrencyDollarIcon className="h-8 w-8" /> 
              },
              { 
                title: 'Seamless Integration', 
                description: 'AI integrates smoothly into your workflow, reducing time on sorting and replying.', 
                icon: <PuzzlePieceIcon className="h-8 w-8" /> 
              },
            ].map((benefit, index) => (
              <motion.div 
                key={index} 
                className="bg-white p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="text-indigo-500 mb-4">{benefit.icon}</div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2">{benefit.title}</h4>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section id="roi" className="py-20 bg-gradient-to-br from-purple-100 to-indigo-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold text-center mb-6 text-gray-800">Calculate Your Potential Savings</h2>
          <p className="text-center mb-12 text-lg text-gray-600">See how much time and money Inktuitive Email could save your business.</p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
            transition={{ duration: 0.5 }}
          >
            <ROICalculator />
          </motion.div>
        </div>
      </section>

      <section id="register" className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">Register Your Interest</h2>
          <p className="text-xl mb-8">
            Be among the first to experience the future of AI-powered email management. Register your interest now!
          </p>
          <motion.div
            className="bg-white p-8 rounded-lg shadow-2xl"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <form onSubmit={handleInterestRegistration} className="flex flex-col items-center gap-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="input input-lg w-full bg-gray-100 text-gray-800"
                required
              />
              <button type="submit" className="btn btn-primary btn-lg w-full" disabled={isSubmitting}>
                {isSubmitting ? 'Registering...' : 'Register Interest'}
              </button>
              {feedbackMessage && (
                <div className={`mt-4 p-3 rounded-md w-full ${
                  feedbackType === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {feedbackMessage}
                </div>
              )}
            </form>
            <p className="text-sm mt-4 text-gray-600">
              By registering your interest, you agree to receive updates about our product. We respect your privacy and won't share your information.
            </p>
          </motion.div>
        </div>
      </section>


    </div>
    </PageLayout>

  );
};

export default LandingPage;
// File: frontend/src/components/admin/UserList.tsx

import React from 'react';
import { User } from '../../types/user';
import { PencilIcon } from '@heroicons/react/24/solid';
import Toggle from '../layout/Toggle';

interface UserListProps {
    users: User[];
    onEditUser: (user: User) => void;
    onToggleChange: (userId: number, isActive: boolean) => void;
    onSort: (field: string) => void;
    sortField: string;
    sortOrder: 'asc' | 'desc';
}

const UserList: React.FC<UserListProps> = ({ users, onEditUser, onToggleChange, onSort, sortField, sortOrder }) => {
    if (users.length === 0) {
        return (
            <div className="text-center py-8 text-gray-500">
                No users found.
            </div>
        );
    }

    const getFriendlyName = (value: string | undefined): string => {
        if (!value) return 'N/A';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    };

    // Function to render sort indicators
    const renderSortIndicator = (field: string) => {
        if (sortField !== field) return null;
        return sortOrder === 'asc' ? '↑' : '↓';
    };

    return (
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {/* Actions Header */}
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        
                        {/* Email Header with Sorting */}
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => onSort('email')}
                        >
                            Email {renderSortIndicator('email')}
                        </th>
                        
                        {/* Organization Header with Sorting */}
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => onSort('organizationName')}
                        >
                            Organisation {renderSortIndicator('organizationName')}
                        </th>
                        
                        {/* Role Header with Sorting */}
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => onSort('role')}
                        >
                            Role {renderSortIndicator('role')}
                        </th>
                        
                        {/* Listener Active Header */}
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listener Active</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                        <tr
                            key={user.id}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => onEditUser(user)}  // Make the entire row clickable
                        >
                            {/* Actions */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <button
                                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none inline-flex items-center"
                                >
                                    <PencilIcon className="h-4 w-4 mr-1" />
                                    Edit
                                </button>
                            </td>
                            
                            {/* Email */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.email}</td>
                            
                            {/* Organization */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.organizationName || 'N/A'}</td>
                            
                            {/* Role */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getFriendlyName(user.role)}</td>
                            
                            {/* Listener Active */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div onClick={(e) => e.stopPropagation()}> {/* Prevent row click when toggle is clicked */}
                                    <Toggle
                                        id={`listener-active-${user.id}`}
                                        checked={user.listener_active || false}
                                        onChange={(checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
                                            event.stopPropagation();  // Prevent row click event from firing
                                            onToggleChange(user.id, checked);
                                        }}
                                        label=""
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

};

export default UserList;

// File: frontend/src/components/admin/AgentModal.tsx

import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline'; 
import { motion, AnimatePresence } from 'framer-motion';
import { useToast } from '../../context/ToastContext'; 

const AgentModal: React.FC<{ agent: any, onClose: () => void, onSubmit: (data: any) => void }> = ({ agent, onClose, onSubmit }) => {
  const [agentName, setAgentName] = useState('');
  const [model, setModel] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(100);
  const { showToast } = useToast(); 


  // Close modal on 'esc' key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (agent) {
      setAgentName(agent.agent_name);
      setModel(agent.model);
      setSystemPrompt(agent.system_prompt);
      setTemperature(agent.temperature);
      setMaxTokens(agent.max_tokens);
    }
  }, [agent]);

  const handleSubmit = () => {
    const agentData = { agent_name: agentName, model, system_prompt: systemPrompt, temperature, max_tokens: maxTokens };
    onSubmit(agentData);
    // Show a success toast notification
    showToast(agent ? 'Agent updated successfully' : 'Agent created successfully', 'success');
    
    onClose(); // Close the modal after submission
  };

  return (
    <AnimatePresence>
      {true && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="modal-container"
        >
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            className="modal-content"
          >
            {/* Modal Header with Close Button */}
            <div className="modal-header">
              <h3 className="text-xl font-bold">{agent ? 'Edit Agent' : 'Create New Agent'}</h3>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {/* Scrollable Content */}
            <div className="p-6 space-y-6">
              <div>
                <label htmlFor="agent_name" className="block text-sm font-medium text-gray-700 mb-1">Agent Name</label>
                <input
                  type="text"
                  id="agent_name"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              {/* Model */}
              <div>
                <label htmlFor="model" className="block text-sm font-medium text-gray-700 mb-1">Model</label>
                <select
                  id="model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="OPENAI">OPENAI</option>
                  <option value="ANTHROPIC">ANTHROPIC</option>
                </select>
              </div>

              {/* System Prompt */}
              <div>
                <label htmlFor="system_prompt" className="block text-sm font-medium text-gray-700 mb-1">System Prompt</label>
                <textarea
                  id="system_prompt"
                  value={systemPrompt}
                  onChange={(e) => setSystemPrompt(e.target.value)}
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              {/* Temperature */}
              <div>
                <label htmlFor="temperature" className="block text-sm font-medium text-gray-700 mb-1">Temperature</label>
                <input
                  type="number"
                  id="temperature"
                  value={temperature}
                  onChange={(e) => setTemperature(parseFloat(e.target.value))}
                  min={0.1}
                  max={1}
                  step={0.1}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              {/* Max Tokens */}
              <div>
                <label htmlFor="max_tokens" className="block text-sm font-medium text-gray-700 mb-1">Max Tokens</label>
                <input
                  type="number"
                  id="max_tokens"
                  value={maxTokens}
                  onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
                  min={1}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4 border-t border-gray-200 pt-4">
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                >
                  {agent ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AgentModal;
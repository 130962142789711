import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { RootState } from './store/store';
import { restoreSession } from './store/slices/authSlice';
import NavBar from './components/layout/NavBar';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/user/Dashboard';
import Settings from './pages/user/Settings';
import Support from './pages/user/Support';
import AdminPanel from './pages/admin/AdminPanel';
import AdminDashboard from './components/admin/AdminDashboard';
import UsersManagement from './components/admin/UsersManagement';
import AgentsManagement from './components/admin/AgentsManagement';
import Organizations from './components/admin/Organizations';
import LogsView from './components/admin/LogsView';
import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';
import FeaturePage from './pages/FeaturePage';
import AboutPage from './pages/AboutPage';
import AdminRoute from './components/AdminRoute';
import AdminSettings from './components/admin/AdminSettings'; 
import { ToastProvider } from './context/ToastContext';

const App: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isSessionRestored, setIsSessionRestored] = useState(false);

  useEffect(() => {
    const restoreUserSession = async () => {
      try {
        await dispatch(restoreSession());  
      } finally {
        setIsSessionRestored(true);  
      }
    };

    restoreUserSession();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);  
  }, [location]);

  const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    if (!isSessionRestored) {
      return null; 
    }
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.3
  };

  return (
    <ToastProvider>
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-indigo-600 to-purple-600">
        <NavBar />
        <main className="flex-grow flex items-center justify-center p-4">
          <AnimatePresence mode='wait'>
            <motion.div
              key={location.pathname}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
              layoutScroll={false}
              className="w-full max-w-7xl min-h-screen"
            >
              {isSessionRestored && (
                <Routes location={location}>
                  <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LandingPage />} />
                  <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
                  <Route path="/register" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Register />} />
                  <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                  <Route path="/support/*" element={<ProtectedRoute element={<Support />} />} />
                  <Route path="/settings/*" element={<ProtectedRoute element={<Settings />} />} />

                  {/* Public Web Pages */}
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/features" element={<FeaturePage />} />
                  
                  {/* Admin Routes */}
                  <Route path="/admin" element={<AdminRoute element={<AdminPanel />} />}>
                    <Route index element={<AdminDashboard />} />
                    <Route path="users" element={<UsersManagement />} />
                    <Route path="agents" element={<AgentsManagement />} />
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="logs" element={<LogsView />} />
                    <Route path="settings" element={<AdminSettings />} /> 
                  </Route>

                </Routes>
              )}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </ToastProvider>
  );
};

export default App;
// File: frontend/src/services/emailSettingsApi.ts

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL  || 'http://localhost:8003/api/v1';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export enum RuleType {
  INCLUDE_DOMAINS = "INCLUDE_DOMAINS",
  EXCLUDE_DOMAINS = "EXCLUDE_DOMAINS",
  KEYWORD_INCLUDE = "KEYWORD_INCLUDE",
  KEYWORD_EXCLUDE = "KEYWORD_EXCLUDE",
  SENDER_INCLUDE = "SENDER_INCLUDE",
  SENDER_EXCLUDE = "SENDER_EXCLUDE",
  CUSTOM = "CUSTOM"
}

export interface EmailSortingRule {
  id?: number;
  rule_type: RuleType;
  rule_data: { value: string };
  description: string;
  category_name: string;
}

export interface Category {
  id?: string; 
  name: string;
  is_app_created: boolean;
  rules?: EmailSortingRule[];
  response_required: boolean;
}

export interface EmailCategoryConfigUpdate {
  categories: Category[];
  rules: EmailSortingRule[];

}

export const emailSettingsApi = {
  getEmailCategories: async (userId?: number): Promise<Category[]> => {
    try {
      const response = await axiosInstance.get('settings/categories', {
        params: { user_id: userId }
      });
      
      const categories = response.data;
      if (!Array.isArray(categories)) {
        throw new Error('Invalid response format: expected an array');
      }
      
      return categories.map((cat: any): Category => ({
        id: cat.id,
        name: typeof cat.name === 'object' && cat.name !== null ? cat.name.name : cat.name || '',
        is_app_created: typeof cat.name === 'object' && cat.name !== null ? cat.name.is_app_created : cat.is_app_created,
        rules: Array.isArray(cat.rules) ? cat.rules : [],
        response_required: typeof cat.response_required === 'object' && cat.response_required!== null? cat.response_required.response_required : cat.response_required,
      }));
    } catch (error) {
      console.error('Error fetching email categories:', error);
      throw error;
    }
  },

  updateEmailCategories: async (configUpdate: EmailCategoryConfigUpdate, userId?: number): Promise<void> => {
    try {
      await axiosInstance.put('settings/categories', configUpdate, {
        params: { user_id: userId }
      });
    } catch (error) {
      console.error('Error updating email categories:', error);
      throw error;
    }
  },

  getGmailLabels: async (userId?: number): Promise<string[]> => {
    try {
      const response = await axiosInstance.get('settings/gmail-labels', {
        params: { user_id: userId }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Gmail labels:', error);
      throw error;
    }
  },

  createGmailLabel: async (labelName: string, userId?: number): Promise<{ message: string; label?: any }> => {
    try {
      const response = await axiosInstance.post('settings/gmail-labels', { name: labelName }, {
        params: { user_id: userId }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating Gmail label:', error);
      throw error;
    }
  },

  removeEmailCategory: async (categoryName: string, removeFromGmail: boolean): Promise<{ success: boolean; message: string }> => {
    try {
      const response = await axiosInstance.delete(`/settings/categories/${encodeURIComponent(categoryName)}`, {
        params: { remove_from_gmail: removeFromGmail }
      });
      return response.data;
    } catch (error) {
      console.error('Error removing email category:', error);
      throw error;
    }
  },

  getCategoryRules: async (categoryName: string): Promise<EmailSortingRule[]> => {
    const response = await axiosInstance.get(`/settings/categories/${encodeURIComponent(categoryName)}/rules`);
    return response.data;
  },

  addRuleToCategory: async (categoryName: string, rule: EmailSortingRule): Promise<EmailSortingRule> => {
    try {
      const response = await axiosInstance.post(`settings/categories/${encodeURIComponent(categoryName)}/rules`, rule);
      return response.data;
    } catch (error) {
      console.error('Error adding rule to category:', error);
      throw error;
    }
  },

  updateRuleInCategory: async (categoryName: string, ruleId: number, rule: EmailSortingRule, userId?: number): Promise<EmailSortingRule> => {
    try {
      const response = await axiosInstance.put(`settings/categories/${encodeURIComponent(categoryName)}/rules/${ruleId}`, rule, {
        params: { user_id: userId }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating rule in category:', error);
      throw error;
    }
  },

  removeRuleFromCategory: async (categoryName: string, ruleId: number, userId?: number): Promise<{ message: string }> => {
    try {
      const response = await axiosInstance.delete(`settings/categories/${encodeURIComponent(categoryName)}/rules/${ruleId}`, {
        params: { user_id: userId }
      });
      return response.data;
    } catch (error) {
      console.error('Error removing rule from category:', error);
      throw error;
    }
  },
};

export default emailSettingsApi;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { updateUserSignature, getUserSignature } from '../../services/api';
import { useToast } from '../../context/ToastContext'; // Import the useToast hook

interface SignatureSettingsProps {
  userId: number;
}

const SignatureSettings: React.FC<SignatureSettingsProps> = ({ userId }) => {
  const [signature, setSignature] = useState('');
  const [previewSignature, setPreviewSignature] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Ref to prevent multiple fetchSignature calls
  const didFetchSignature = useRef(false);

  const { showToast } = useToast(); // Use the useToast hook

  const fetchSignature = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedSignature = await getUserSignature(userId);
      setSignature(fetchedSignature);
      setPreviewSignature(fetchedSignature);
      setError(null);
      showToast('Signature loaded successfully', 'success');
    } catch (err) {
      setError('Failed to fetch signature');
      console.error('Error fetching signature:', err);
      showToast('Failed to load signature', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showToast, userId]);

  useEffect(() => {
    if (!didFetchSignature.current) {
      fetchSignature();
      didFetchSignature.current = true;
    }
  }, [userId, fetchSignature]);

  const handleSignatureChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignature(e.target.value);
    setPreviewSignature(e.target.value);
  };

  const handleSaveSignature = async () => {
    setIsLoading(true);
    try {
      await updateUserSignature(userId, { signature });
      setError(null);
      showToast('Signature saved successfully', 'success');
    } catch (err) {
      setError('Failed to save signature');
      console.error('Error saving signature:', err);
      showToast('Failed to save signature', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="text-center py-8">Loading...</div>;

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-800">Email Signature</h2>
      
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div>
        <label htmlFor="signature" className="block text-sm font-medium text-gray-700 mb-1">
          Your Signature
        </label>
        <textarea
          id="signature"
          rows={6}
          value={signature}
          onChange={handleSignatureChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Enter your email signature here"
        />
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-800 mb-2">Preview</h3>
        <div
          className="border border-gray-300 p-4 rounded-md bg-white"
          dangerouslySetInnerHTML={{ __html: previewSignature }}
        />
      </div>

      <div className="flex justify-end pt-4 border-t border-gray-200">
        <button
          onClick={handleSaveSignature}
          className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
          disabled={isLoading}
        >
          Save Signature
        </button>
      </div>
    </div>
  );
};

export default SignatureSettings;

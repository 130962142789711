import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  InboxIcon,
  SparklesIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import {
  emailSettingsApi,
  Category,
  EmailSortingRule,
  RuleType,
  EmailCategoryConfigUpdate,
} from '../../services/emailSettingsApi';
import adminApi from '../../services/adminApi';
import ConfirmationModal from './ConfirmationModal';
import { UserRole } from '../../types/user';
import { useToast } from '../../context/ToastContext'; // Import the useToast hook

interface EmailSortingSettingsProps {
  userId: number;
}

const suggestedCategories = [
  'Urgent',
  'Internal',
  'Sales',
  'Client',
  'Notifications',
  'News & Updates',
  'Support',
  'Travel',
  'Personal',
  'Finance',
  'Other',
];

const EmailSortingSettings: React.FC<EmailSortingSettingsProps> = ({ userId }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [newCategory, setNewCategory] = useState('');
  const [gmailLabels, setGmailLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [categoryToRemove, setCategoryToRemove] = useState<string | null>(null);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [processingCategoryId, setProcessingCategoryId] = useState<string | null>(null);
  const [processingRuleId, setProcessingRuleId] = useState<number | null>(null);

  const { showToast } = useToast(); // Use the useToast hook

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  const currentUser = useSelector((state: RootState) => state.auth.user);
  const isAdmin = useMemo(() => currentUser?.role === UserRole.ADMIN, [currentUser]);

  const [newRule, setNewRule] = useState<Partial<EmailSortingRule>>({
    rule_type: RuleType.INCLUDE_DOMAINS,
    rule_data: { value: '' },
    description: 'New rule',
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        let fetchedCategories;
        if (isAdmin) {
          fetchedCategories = await adminApi.getEmailCategories(userId);
        } else {
          fetchedCategories = await emailSettingsApi.getEmailCategories();
        }
        console.log('Fetched categories:', fetchedCategories);

        const categoriesWithRules = await Promise.all(
          fetchedCategories.map(async (category) => {
            if (!category.rules || category.rules.length === 0) {
              let rules;
              if (isAdmin) {
                rules = await adminApi.getCategoryRules(userId, encodeURIComponent(category.name));
              } else {
                rules = await emailSettingsApi.getCategoryRules(encodeURIComponent(category.name));
              }
              return { ...category, rules };
            }
            return category;
          })
        );

        setCategories(categoriesWithRules);
        setError(null);
      } catch (err) {
        console.error('Error fetching email categories:', err);
        setError('Failed to fetch email categories');
        showToast('Failed to load email categories', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchGmailLabels = async () => {
      try {
        const labels = isAdmin
          ? await adminApi.getGmailLabels(userId)
          : await emailSettingsApi.getGmailLabels();
        setGmailLabels(labels);
        showToast('Gmail labels loaded successfully', 'success');
      } catch (err) {
        console.error('Error fetching Gmail labels:', err);
        setError('Failed to fetch Gmail labels');
        showToast('Failed to load Gmail labels', 'error');
      }
    };

    fetchCategories();
    fetchGmailLabels();
  }, [userId, isAdmin, showToast]);

  const refreshGmailLabels = async () => {
    try {
      const labels = isAdmin
        ? await adminApi.getGmailLabels(userId)
        : await emailSettingsApi.getGmailLabels();
      setGmailLabels(labels);
    } catch (err) {
      console.error('Error refreshing Gmail labels:', err);
      setError('Failed to refresh Gmail labels');
      showToast('Failed to refresh Gmail labels', 'error');
    }
  };

  const handleAddCategory = async () => {
    if (newCategory && categories.length < 10 && !categories.some(cat => cat.name === newCategory)) {
      try {
        const isGmailLabel = gmailLabels.includes(newCategory);
        const newCat: Category = {
          name: newCategory,
          is_app_created: !isGmailLabel,
          rules: [],
          response_required: true,
        };
        await updateCategories([...categories, newCat]);
        setNewCategory('');
        if (!isGmailLabel) {
          const result = isAdmin
            ? await adminApi.createGmailLabel(newCategory, userId)
            : await emailSettingsApi.createGmailLabel(newCategory);
          if (result.message.includes('already exists')) {
            console.log(result.message);
          }
        }
        await refreshGmailLabels();
      } catch (err) {
        console.error('Error adding category:', err);
        setError('Failed to add category. It might already exist in Gmail.');
        showToast('Failed to add category', 'error');
      }
    }
  };

  const handleSuggestedCategoryClick = async (category: string) => {
    if (categories.length < 10 && !categories.some((c) => c.name === category)) {
      try {
        const newCat: Category = {
          name: category,
          is_app_created: true,
          rules: [],
          response_required: true,
        };
        await updateCategories([...categories, newCat]);
        if (isAdmin) {
          await adminApi.createGmailLabel(category, userId);
        } else {
          await emailSettingsApi.createGmailLabel(category);
        }
        await refreshGmailLabels();
      } catch (err) {
        console.error('Error adding suggested category:', err);
        setError('Failed to add suggested category.');
        showToast('Failed to add suggested category', 'error');
      }
    }
  };

  const handleRemoveCategory = (categoryName: string) => {
    setCategoryToRemove(categoryName);
    setShowModal(true);
  };

  const confirmRemoveCategory = async (option: string) => {
    if (categoryToRemove) {
      try {
        setIsDeleting(true);
        setProcessingCategoryId(categoryToRemove);
        setIsLoading(true);

        const result = isAdmin
          ? await adminApi.removeEmailCategory(userId, encodeURIComponent(categoryToRemove), option === 'both')
          : await emailSettingsApi.removeEmailCategory(encodeURIComponent(categoryToRemove), option === 'both');

        if (result.success) {
          setCategories((prevCategories) =>
            prevCategories.filter((cat) => cat.name !== categoryToRemove)
          );
          setError(null);

          if (option === 'both') {
            await refreshGmailLabels();
          }
          showToast(`Category "${categoryToRemove}" removed successfully`, 'success');
        } else {
          setError(result.message);
          showToast(result.message, 'error');
        }
      } catch (err) {
        console.error('Error removing category:', err);
        setError('Failed to remove category. Please try again.');
        showToast('Failed to remove category', 'error');
      } finally {
        setIsDeleting(false);
        setProcessingCategoryId(null);
        setIsLoading(false);
        setShowModal(false);
        setCategoryToRemove(null);
      }
    }
  };

  const handleMoveCategory = async (index: number, direction: 'up' | 'down') => {
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === categories.length - 1)
    ) {
      return;
    }

    const newCategories = [...categories];
    const [movedCategory] = newCategories.splice(index, 1);
    newCategories.splice(direction === 'up' ? index - 1 : index + 1, 0, movedCategory);

    await updateCategories(newCategories);
    showToast(`Category "${movedCategory.name}" moved ${direction}`, 'success');
  };

  const updateCategories = async (categoriesToSave: Category[]) => {
    try {
      setIsLoading(true);
      const configUpdate: EmailCategoryConfigUpdate = {
        categories: categoriesToSave.map((cat) => ({
          name: cat.name,
          is_app_created: cat.is_app_created,
          response_required: cat.response_required,
        })),
        rules: categoriesToSave.flatMap((cat) => cat.rules || []),
      };

      if (isAdmin) {
        await adminApi.updateEmailCategories(configUpdate, userId);
      } else {
        await emailSettingsApi.updateEmailCategories(configUpdate);
      }
      setCategories(categoriesToSave);
      setError(null);
      showToast('Categories updated successfully', 'success');
    } catch (err) {
      console.error('Error saving categories:', err);
      setError('Failed to save email categories');
      showToast('Failed to update categories', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleResponseRequired = (index: number) => {
    const updatedCategories = [...categories];
    updatedCategories[index] = {
      ...updatedCategories[index],
      response_required: !updatedCategories[index].response_required,
    };
    updateCategories(updatedCategories);
    showToast(`Priority Inbox & Responses toggled for "${updatedCategories[index].name}"`, 'info');
  };

  const handleAddRule = async (categoryName: string) => {
    try {
      const ruleToAdd: EmailSortingRule = {
        rule_type: newRule.rule_type!,
        rule_data: { value: newRule.rule_data!.value },
        description: newRule.description || 'New rule',
        category_name: categoryName,
      };

      const result = isAdmin
        ? await adminApi.addRuleToCategory(userId, encodeURIComponent(categoryName), ruleToAdd)
        : await emailSettingsApi.addRuleToCategory(encodeURIComponent(categoryName), ruleToAdd);

      const updatedCategories = categories.map((cat) => {
        if (cat.name === categoryName) {
          return {
            ...cat,
            rules: [...(cat.rules || []), result],
          };
        }
        return cat;
      });
      setCategories(updatedCategories);
      setNewRule({ rule_type: RuleType.INCLUDE_DOMAINS, rule_data: { value: '' }, description: 'New rule' });
      showToast(`Rule added to category "${categoryName}"`, 'success');
    } catch (err) {
      console.error('Error adding rule:', err);
      setError('Failed to add rule. Please try again.');
      showToast('Failed to add rule', 'error');
    }
  };

  const handleRemoveRule = async (categoryName: string, ruleId: number) => {
    try {
      setIsDeleting(true);
      setProcessingRuleId(ruleId);
      setIsLoading(true);

      const encodedCategory = encodeURIComponent(categoryName);
      if (isAdmin) {
        await adminApi.removeRuleFromCategory(userId, encodedCategory, ruleId);
      } else {
        await emailSettingsApi.removeRuleFromCategory(encodedCategory, ruleId);
      }

      const updatedCategories = categories.map((cat) =>
        cat.name === categoryName
          ? { ...cat, rules: cat.rules?.filter((rule) => rule.id !== ruleId) }
          : cat
      );
      setCategories(updatedCategories);
      showToast(`Rule removed from category "${categoryName}"`, 'success');
    } catch (err) {
      console.error('Error removing rule:', err);
      setError('Failed to remove rule. Please try again.');
      showToast('Failed to remove rule', 'error');
    } finally {
      setIsDeleting(false);
      setProcessingRuleId(null);
      setIsLoading(false);
    }
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setNewCategory(selectedValue);
    
    // If the selected value is in gmailLabels, we can optionally do something here,
    // like automatically adding the category
    if (gmailLabels.includes(selectedValue) && !categories.some(cat => cat.name === selectedValue)) {
      handleAddCategory();
    }
  };


  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-6">Email Sorting Categories</h2>
      <p className="mb-4">
        Create a list of up to 10 categories for your emails to be sorted into. You can choose from
        suggested categories, your own existing labels, or create new ones.
      </p>
      {error && <div className="alert alert-error mb-4">{error}</div>}

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Suggested Categories</h3>
        <div className="flex flex-wrap gap-2 mb-4">
          {suggestedCategories.map((category) => (
            <button
              key={category}
              onClick={() => handleSuggestedCategoryClick(category)}
              className={`btn btn-sm ${
                categories.some((c) => c.name === category)
                  ? 'btn-success btn-outline'
                  : 'btn-ghost'
              }`}
            >
              {category}
              {categories.some((c) => c.name === category) && (
                <CheckIcon className="inline-block w-4 h-4 ml-1" />
              )}
            </button>
          ))}
        </div>

        <h3 className="text-lg font-semibold mb-2">Your Category List</h3>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : (
          <ul className="space-y-2">
            {categories.map((category, index) => (
              <li
                key={`${category.name}-${index}`}
                className={`card ${
                  category.is_app_created ? 'bg-indigo-100' : 'bg-green-100'
                }`}
              >
                <div className="card-body p-4">
                  <div className="flex items-center justify-between">
                    <span className="flex items-center">
                      {category.is_app_created ? (
                        <SparklesIcon className="h-4 w-4 mr-2 text-indigo-500" />
                      ) : (
                        <InboxIcon className="h-4 w-4 mr-2 text-green-500" />
                      )}
                      <span className="font-medium">{category.name}</span>
                    </span>
                    <div className="flex items-center space-x-2">
                      <label className="label cursor-pointer">
                        <span className="label-text mr-2">Priority Inbox & Responses</span>
                        <input
                          type="checkbox"
                          className="toggle toggle-primary"
                          checked={category.response_required}
                          onChange={() => handleToggleResponseRequired(index)}
                        />
                      </label>

                      <div className="flex flex-col">
                        <button
                          onClick={() => handleMoveCategory(index, 'up')}
                          disabled={index === 0}
                          className={`btn btn-xs btn-circle ${
                            index === 0 ? 'btn-disabled' : 'btn-primary'
                          }`}
                          aria-label="Move category up"
                        >
                          <ChevronUpIcon className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleMoveCategory(index, 'down')}
                          disabled={index === categories.length - 1}
                          className={`btn btn-xs btn-circle ${
                            index === categories.length - 1 ? 'btn-disabled' : 'btn-primary'
                          }`}
                          aria-label="Move category down"
                        >
                          <ChevronDownIcon className="h-4 w-4" />
                        </button>
                      </div>
                      <button
                        onClick={() => handleRemoveCategory(category.name)}
                        className={`text-red-600 hover:text-red-800 ${
                          isDeleting || processingCategoryId === category.name
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        disabled={isDeleting || processingCategoryId === category.name}
                      >
                        {processingCategoryId === category.name ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          <TrashIcon className="h-6 w-6" />
                        )}
                      </button>
                      <button
                        onClick={() =>
                          setExpandedCategory(
                            expandedCategory === category.name ? null : category.name
                          )
                        }
                        className="p-0.5 rounded text-blue-500 hover:bg-blue-100"
                        aria-label={
                          expandedCategory === category.name ? 'Hide rules' : 'Show rules'
                        }
                      >
                        {expandedCategory === category.name ? (
                          <ChevronUpIcon className="h-4 w-4" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" />
                        )}
                      </button>
                    </div>
                  </div>
                  {expandedCategory === category.name && (
                    <div className="mt-4">
                      <h4 className="font-semibold mb-2">Rules</h4>
                      {category.rules && category.rules.length > 0 ? (
                        <ul className="space-y-2">
                          {category.rules.map((rule) => (
                            <li key={rule.id} className="flex items-center justify-between">
                              <span>
                                {rule.rule_type}: {rule.rule_data.value}
                              </span>
                              <button
                                onClick={() => rule.id && handleRemoveRule(category.name, rule.id)}
                                className={`text-red-600 hover:text-red-800 ${
                                  isDeleting || processingRuleId === rule.id
                                    ? 'opacity-50 cursor-not-allowed'
                                    : ''
                                }`}
                                disabled={isDeleting || processingRuleId === rule.id}
                              >
                                {processingRuleId === rule.id ? (
                                  <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                  'Remove Rule'
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-500">No rules yet</p>
                      )}
                      <div className="mt-2 space-y-2">
                        <select
                          value={newRule.rule_type}
                          onChange={(e) =>
                            setNewRule({ ...newRule, rule_type: e.target.value as RuleType })
                          }
                          className="select select-bordered w-full"
                        >
                          {Object.values(RuleType).map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        <input
                          type="text"
                          value={newRule.rule_data?.value || ''}
                          onChange={(e) =>
                            setNewRule({ ...newRule, rule_data: { value: e.target.value } })
                          }
                          placeholder="Enter rule data"
                          className="input input-bordered w-full"
                        />
                        <button onClick={() => handleAddRule(category.name)} className="btn btn-primary btn-sm">
                          <PlusIcon className="h-4 w-4 mr-1" />
                          Add Rule
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}

        {categories.length < 10 && (
        <div className="mt-4 flex">
          <input
            type="text"
            value={newCategory}
            onChange={handleInputChange}
            placeholder="New category name"
            className="input input-bordered flex-grow mr-2"
            list="gmail-labels"
          />
          <datalist id="gmail-labels">
            {gmailLabels
              .filter(label => !categories.some(cat => cat.name === label))
              .map((label) => (
                <option key={label} value={label} />
              ))}
          </datalist>
          <button onClick={handleAddCategory} className="btn btn-primary">
            Add Category
          </button>
        </div>
      )}
      </div>

      <ConfirmationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmRemoveCategory}
        title="Remove Category"
        message={`Are you sure you want to remove the category "${categoryToRemove}"?`}
        options={[
          { value: 'both', label: 'Remove from Gmail and sorting categories' },
          { value: 'app_only', label: 'Remove from sorting categories only' },
        ]}
      />
    </div>
  );
};

export default EmailSortingSettings;

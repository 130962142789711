import React, { useState, useEffect, useMemo } from 'react';
import businessSettingsApi from '../../services/businessSettingsApi';
import { Organization } from '../../types/settings';
import OrganizationModal from './OrganizationModal';
import { PencilIcon } from '@heroicons/react/24/solid';

const Organizations: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<Organization[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, ] = useState(10);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      setIsLoading(true);
      const fetchedOrgs = await businessSettingsApi.getOrganizations();
      if (Array.isArray(fetchedOrgs)) {
        setOrganizations(fetchedOrgs);
        setFilteredOrganizations(fetchedOrgs);
      } else {
        setError('Received invalid data format from the server');
      }
    } catch (err) {
      setError('Failed to fetch organizations');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredOrganizations(
      organizations.filter(org =>
        org.name.toLowerCase().includes(searchValue) ||
        org.domain.toLowerCase().includes(searchValue)
      )
    );
    setCurrentPage(1); // Reset to first page on search
  };

  const handleRowClick = (org: Organization) => {
    setSelectedOrg(org);
    setIsCreating(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedOrg(null);
    setIsCreating(false);
    setIsModalOpen(false);
    fetchOrganizations();
  };

  const handleCreateClick = () => {
    setSelectedOrg(null);
    setIsCreating(true);
    setIsModalOpen(true);
  };

  // Pagination handlers
  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredOrganizations.length / itemsPerPage)));

  // Memoized paginated organizations
  const paginatedOrganizations = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredOrganizations.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredOrganizations, currentPage, itemsPerPage]);

  if (isLoading) return <div>Loading organisations...</div>;

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-6">Organisations</h2>
      <div className="flex space-x-4 mb-6 w-full">
        <input
          type="text"
          placeholder="Search organisations..."
          value={searchTerm}
          onChange={handleSearch}
          className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
        />
        <button
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
          onClick={handleCreateClick}
        >
          Create Organisation
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      {Array.isArray(paginatedOrganizations) && paginatedOrganizations.length > 0 ? (
        <>
          <table className="min-w-full divide-y divide-gray-200 shadow-md sm:rounded-lg">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Domain</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">User Count</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedOrganizations.map((org) => (
                <tr
                  key={org.id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRowClick(org)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none inline-flex items-center"
                    >
                      <PencilIcon className="h-4 w-4 mr-1" />
                      Edit
                    </button>
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{org.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{org.domain}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{org.users?.length || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
          
          {/* Pagination controls */}
          <div className="flex justify-center mt-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="px-4 py-2 mr-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span className="px-4 py-2">{currentPage}</span>
            <button
              onClick={handleNextPage}
              disabled={currentPage * itemsPerPage >= filteredOrganizations.length}
              className="px-4 py-2 ml-2 bg-gray-200 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div>No organisations found.</div>
      )}

      <OrganizationModal
        organization={selectedOrg}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        refreshOrganizations={fetchOrganizations}
        isCreating={isCreating}
      />
    </div>
  );
};

export default Organizations;

// File: frontend/src/pages/Settings.tsx

import React, { useEffect } from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { RootState, AppDispatch } from '../../store/store';
import { fetchUserData } from '../../store/slices/authSlice';
import EmailSortingSettings from '../../components/settings/EmailSortingSettings';
import UserContextSettings from '../../components/settings/UserContextSettings';
import SignatureSettings from '../../components/settings/SignatureSettings';

const Settings: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.user);

  // Fetch user data if not already available
  useEffect(() => {
    if (!user) {
      dispatch(fetchUserData());
    }
  }, [dispatch, user]);

  // Display a loading state if user data is not yet available
  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-6 flex items-center justify-center">
        <div className="bg-white rounded-xl shadow-2xl p-8">
          <p className="text-xl font-semibold text-gray-800">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-6"
    >
      <div className="bg-white rounded-xl shadow-2xl p-8 mb-10">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-8">Settings</h1>

        <div className="mb-8">
          <nav className="flex space-x-2">
            <NavLink
              to="/settings/user-context"
              className={({ isActive }) =>
                `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  isActive
                    ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`
              }
            >
              User Context
            </NavLink>
            <NavLink
              to="/settings/email-sorting"
              className={({ isActive }) =>
                `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  isActive
                    ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`
              }
            >
              Email Sorting
            </NavLink>
            <NavLink
              to="/settings/signature"
              className={({ isActive }) =>
                `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  isActive
                    ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`
              }
            >
              Signature
            </NavLink>
          </nav>
        </div>

        <div className="bg-gray-50 rounded-lg p-6">
          <Routes>
            {/* Redirect to 'user-context' if the path is '/settings' */}
            <Route index element={<Navigate to="user-context" replace />} />
            <Route path="user-context" element={<UserContextSettings userId={user.id} />} />
            <Route path="email-sorting" element={<EmailSortingSettings userId={user.id} />} />
            <Route path="signature" element={<SignatureSettings userId={user.id} />} />
          </Routes>
        </div>
      </div>
    </motion.div>
  );
};

export default Settings;

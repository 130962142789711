// file: frontend/src/services/agentApi.ts

import api from './api';

export const getAgents = async () => {
  const response = await api.get('/admin/agents'); // Use pre-configured `api`
  return response.data;
};

export const getAgent = async (agentName: string) => {
  const response = await api.get(`/admin/agents/${agentName}`);
  return response.data;
};

export const createAgent = async (agentData: any) => {
  const response = await api.post('/admin/agents', agentData);
  return response.data;
};

export const updateAgent = async (agentName: string, agentData: any) => {
  const response = await api.put(`/admin/agents/${agentName}`, agentData);
  return response.data;
};

export const deleteAgent = async (agentName: string) => {
  const response = await api.delete(`/admin/agents/${agentName}`);
  return response.data;
};

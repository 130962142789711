import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UsersIcon, BuildingOfficeIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import businessSettingsApi from '../../services/businessSettingsApi';
import { Organization } from '../../types/settings';
import { User, UserRole } from '../../types/user';

const AdminDashboard: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Add 'scrollable-page' class to body when component mounts
    document.body.classList.add('scrollable-page');
    // Remove 'scrollable-page' class from body when component unmounts
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [fetchedOrganizations, fetchedUsers] = await Promise.all([
          businessSettingsApi.getOrganizations(),
          businessSettingsApi.getUsers()
        ]);
        setOrganizations(fetchedOrganizations);
        setUsers(fetchedUsers);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch dashboard data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const userRoleData = [
    { name: 'Admin', value: users.filter(u => u.role === UserRole.ADMIN).length },
    { name: 'Regular', value: users.filter(u => u.role !== UserRole.ADMIN).length },
  ];

  const unassignedUsersCount = users.filter(user => 
    !organizations.some(org => 
      org.users?.some(orgUser => 
        'id' in orgUser && 'id' in user && orgUser.id === user.id
      )
    )
  ).length;

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="text-red-500 mb-4">{error}</div>;

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-6">Admin Dashboard</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6 flex items-center">
          <BuildingOfficeIcon className="h-10 w-10 text-indigo-600 mr-4" />
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Total Organisations</h3>
            <p className="text-2xl font-bold text-indigo-600">{organizations.length}</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6 flex items-center">
          <UsersIcon className="h-10 w-10 text-green-600 mr-4" />
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Total Users</h3>
            <p className="text-2xl font-bold text-green-600">{users.length}</p>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6 flex items-center">
          <UserGroupIcon className="h-10 w-10 text-yellow-600 mr-4" />
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Unassigned Users</h3>
            <p className="text-2xl font-bold text-yellow-600">{unassignedUsersCount}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-xl font-semibold text-indigo-600 mb-4">User Role Distribution</h3>
          <ul>
            {userRoleData.map((data, index) => (
              <li key={index} className="flex justify-between items-center mb-2">
                <span>{data.name}</span>
                <span>{data.value}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-xl font-semibold text-indigo-600 mb-4">Recent Organisations</h3>
          <ul className="space-y-2">
            {organizations.slice(0, 5).map(org => (
              <li key={org.id} className="flex justify-between items-center">
                <span>{org.name}</span>
                <span className="text-sm text-gray-500">{org.users?.length || 0} users</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <Link to="/admin/users" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
          <h3 className="text-xl font-semibold text-indigo-600 mb-2">Users</h3>
          <p className="text-gray-600">Manage user accounts, roles, and organisation assignments.</p>
        </Link>
        <Link to="/admin/organizations" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
          <h3 className="text-xl font-semibold text-indigo-600 mb-2">Organisations</h3>
          <p className="text-gray-600">Manage organisations, their settings, and user assignments.</p>
        </Link>
        <Link to="/admin/agents" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
          <h3 className="text-xl font-semibold text-indigo-600 mb-2">Agents</h3>
          <p className="text-gray-600">Configure and manage AI agents for email processing.</p>
        </Link>
        <Link to="/admin/logs" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
          <h3 className="text-xl font-semibold text-indigo-600 mb-2">Logs</h3>
          <p className="text-gray-600">View and analyze system logs for troubleshooting and monitoring.</p>
        </Link>
      </div>
    </div>
  );
};

export default AdminDashboard;
import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { RootState } from '../../store/store';
import { UserRole } from '../../types/user';  // Import the UserRole enum

const Dashboard: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-6"
    >
      <div className="bg-white rounded-xl shadow-2xl p-8 mb-10"> {/* Added mb-10 for bottom gap */}
        <h1 className="text-4xl font-extrabold text-gray-800 mb-6">Dashboard</h1>
        
        {user && (
          <div className="bg-indigo-50 rounded-lg p-6 mb-8">
            <h2 className="text-2xl font-bold text-indigo-800 mb-4">Welcome, {user.email}</h2>
            <p className="text-lg text-gray-700">Role: <span className="font-semibold">{user.role}</span></p>
            <p className="text-gray-600 mt-2">
              This is your email management dashboard. Here you can view your account information and access key features of the application.
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <Link to="/settings/user-context" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
            <h3 className="text-xl font-semibold text-indigo-600 mb-2">User Context</h3>
            <p className="text-gray-600">Update your personal information and preferences.</p>
          </Link>
          <Link to="/settings/email-sorting" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
            <h3 className="text-xl font-semibold text-indigo-600 mb-2">Email Sorting</h3>
            <p className="text-gray-600">Manage your email categories and sorting rules.</p>
          </Link>
          <Link to="/settings/signature" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
            <h3 className="text-xl font-semibold text-indigo-600 mb-2">Signature</h3>
            <p className="text-gray-600">Customize your email signature.</p>
          </Link>
          {user && user.role === UserRole.ADMIN && (
            <Link to="/admin" className="bg-white rounded-lg shadow p-6 hover:shadow-md transition duration-300">
              <h3 className="text-xl font-semibold text-indigo-600 mb-2">Admin Panel</h3>
              <p className="text-gray-600">Access administrative functions and settings.</p>
            </Link>
          )}
        </div>

        <div className="bg-gray-50 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Getting Started</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Update your user context to personalize your experience</li>
            <li>Set up email sorting categories and category rules to organize your inbox</li>
            <li>Customize your email signature for a professional touch</li>
            <li>Explore the features available in the settings</li>
            <li>Contact support to configure your AI auto-response (draft) settings</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default Dashboard;

// File: frontend/src/pages/admin/AdminPanel.tsx

import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { getGmailListenerStatus } from '../../services/adminApi';
import { motion } from 'framer-motion';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';

const AdminPanel: React.FC = () => {
  const location = useLocation();
  const [, setIsListenerActive] = useState(false);

  // Fetch Gmail Listener Status
  useEffect(() => {
    const fetchListenerStatus = async () => {
      try {
        const status = await getGmailListenerStatus();
        setIsListenerActive(status.active);
      } catch (error) {
        console.error('Error fetching Gmail listener status:', error);
      }
    };
    fetchListenerStatus();
  }, []);

  // Code is not yet implemented
  // const handleToggleChange = async (checked: boolean) => {
  //   try {
  //     const updatedStatus = await toggleGmailListener(checked);
  //     setIsListenerActive(updatedStatus.active);
  //     console.log(`Gmail listener ${updatedStatus.active ? 'activated' : 'deactivated'}`);
  //   } catch (error) {
  //     console.error('Error toggling global Gmail listener:', error);
  //     try {
  //       const currentStatus = await getGmailListenerStatus();
  //       setIsListenerActive(currentStatus.active);
  //     } catch (statusError) {
  //       console.error('Error fetching current Gmail listener status:', statusError);
  //     }
  //   }
  // };

  const isActive = (path: string) => {
    if (path === '/admin') {
      return location.pathname === '/admin' || location.pathname === '/admin/dashboard';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-6"
    >
      <div className="bg-white rounded-xl shadow-2xl p-8 mb-10">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-extrabold text-gray-800">Admin Panel</h1>
          <NavLink 
            to="/admin/settings" 
            className={({ isActive }) =>
              `p-2 rounded-full transition-colors duration-200 ${
                isActive ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600 hover:bg-gray-100'
              }`
            }
            title="Settings"
          >
            <Cog8ToothIcon className="h-8 w-8" />
          </NavLink>
        </div>

        <nav className="flex space-x-2 mb-8">
          {[
            { name: 'Dashboard', path: '/admin' },
            { name: 'Users', path: '/admin/users' },
            { name: 'Organisations', path: '/admin/organizations' },
            { name: 'Agents', path: '/admin/agents' },
            { name: 'Logs', path: '/admin/logs' },
          ].map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.path}
              className={({ isActive: linkActive }) =>
                `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  isActive(tab.path)
                    ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`
              }
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>

        <div className="bg-gray-50 rounded-lg p-6">
          <Outlet />
        </div>
      </div>
    </motion.div>
  );
};

export default AdminPanel;

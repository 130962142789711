import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store } from './store/store';
import App from './App';
import './index.css';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

if (!googleClientId) {
  console.error('REACT_APP_GOOGLE_CLIENT_ID is not defined in the environment');
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId={googleClientId || ''}>
          <App />
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  //</React.StrictMode>
);
// File: frontend/src/components/admin/AgentsManagement.tsx

import React, { useState, useEffect } from 'react';
import { getAgents, getAgent, updateAgent } from '../../services/agentApi'; 
import Modal from './AgentModal';
import { PencilIcon } from '@heroicons/react/24/solid';  // Importing the pencil icon

// Define the Agent type to match the backend structure
interface Agent {
  id: number;
  agent_name: string;
  model: string;
  system_prompt: string;
  user_prompt: string;
  temperature: number;
  max_tokens: number;
}

const AgentsManagement: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    const agentList = await getAgents();
    setAgents(agentList);
  };

  const handleEdit = async (agentName: string) => {
    const agent = await getAgent(agentName);
    setSelectedAgent(agent);  // Set selected agent for editing
    setIsModalOpen(true);
  };

  const handleSubmit = async (agentData: any) => {
    if (selectedAgent) {
      await updateAgent(selectedAgent.agent_name, agentData);  // Update existing agent
    }
    setIsModalOpen(false);  // Close modal after submit
    fetchAgents();  // Refresh agent list after update
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-6">Agents</h2>

      {agents.length === 0 && <div className="text-gray-500">No agents found.</div>}

      {agents.length > 0 && (
        <table className="min-w-full divide-y divide-gray-200 shadow-md sm:rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Agent Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Model</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {agents.map((agent) => (
              <tr 
                key={agent.id} 
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleEdit(agent.agent_name)}  // Add onClick event to the row
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={(e) => { 
                      e.stopPropagation();  // Prevent the row click event from triggering
                      handleEdit(agent.agent_name); 
                    }}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none inline-flex items-center"
                  >
                    <PencilIcon className="h-4 w-4 mr-1" />
                    Edit
                  </button>
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-900">{agent.agent_name}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{agent.model}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isModalOpen && (
        <Modal
          agent={selectedAgent}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default AgentsManagement;

// File: frontend/src/components/admin/UsersManagement.tsx

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { User } from '../../types/user';
import { Organization } from '../../types/settings';
import { getUsers } from '../../services/api';
import businessSettingsApi from '../../services/businessSettingsApi';
import UserList from './UserList';
import UserModal from './UserModal';
import { useUserActions } from './UserActions';
import { debounce } from 'lodash';

const UsersManagement: React.FC = () => {
    // State variables
    const [users, setUsers] = useState<User[]>([]);
    const [, setOrganizations] = useState<Organization[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [organizationFilter, setOrganizationFilter] = useState<string>('');
    const [domainFilter, setDomainFilter] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    
    // Sorting state
    const [sortField, setSortField] = useState<string>('email');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    // User actions
    const { handleCreateUser, handleUpdateUser, } = useUserActions(setUsers, setError);

    // Fetch users and organizations on component mount
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            // Fetch users and organizations in parallel
            const [fetchedUsers, fetchedOrganizations] = await Promise.all([
                getUsers(),
                businessSettingsApi.getOrganizations(),
            ]);
            
            // Map organization name to each user
            const usersWithOrg = fetchedUsers.map(user => ({
                ...user,
                organizationName: user.organization?.name || 'N/A',
            }));
            
            setUsers(usersWithOrg);
            setOrganizations(fetchedOrganizations);
            setError(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 403) {
                    setError('You do not have permission to view the user list. Please contact an administrator.');
                } else {
                    setError('An error occurred while fetching users. Please try again later.');
                }
            } else {
                setError('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    // Debounced search handler
    const handleSearch = useMemo(() => debounce((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1); // Reset to first page on search
    }, 300), []);

    useEffect(() => {
        return () => {
            handleSearch.cancel();
        };
    }, [handleSearch]);

    // Extract unique organizations and domains for filter options
    const organizationOptions = useMemo(() => {
        return Array.from(new Set(users.map(user => user.organizationName))).filter(org => org !== 'N/A');
    }, [users]);

    const domainOptions = useMemo(() => {
        return Array.from(new Set(users.map(user => {
            const parts = user.email.split('@');
            return parts.length > 1 ? parts[1].toLowerCase() : '';
        }))).filter(Boolean);
    }, [users]);

    // Filtered users based on search and filters
    const filteredUsers = useMemo(() => {
        return users.filter(user => {
            const matchesSearchTerm = user.email.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesOrganization = organizationFilter
                ? user.organizationName === organizationFilter
                : true;

            const matchesDomain = domainFilter
                ? user.email.toLowerCase().endsWith(`@${domainFilter.toLowerCase()}`)
                : true;

            return matchesSearchTerm && matchesOrganization && matchesDomain;
        });
    }, [users, searchTerm, organizationFilter, domainFilter]);

    // Sorted users
    const sortedUsers = useMemo(() => {
        return [...filteredUsers].sort((a, b) => {
            const fieldA = (a as any)[sortField];
            const fieldB = (b as any)[sortField];

            if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
            if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredUsers, sortField, sortOrder]);

    // Paginated users
    const paginatedUsers = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return sortedUsers.slice(startIndex, startIndex + itemsPerPage);
    }, [sortedUsers, currentPage]);

    // Open modal
    const openModal = (user: User | null = null) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    // Close modal
    const closeModal = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
        fetchData(); // Refresh data after closing modal
    };

    // Handle form submit
    const handleFormSubmit = (userData: Partial<User>) => {
        if (selectedUser) {
            // Edit existing user
            handleUpdateUser(selectedUser.id, userData);
        } else {
            // Create a new user
            handleCreateUser(userData);
        }
        closeModal();
    };

    // Handle toggle change
    const handleToggleChange = (userId: number, isActive: boolean) => {
        handleUpdateUser(userId, { listener_active: isActive });
    };

    // Handle sorting
    const handleSort = (field: string) => {
        const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    return (
        <div className="w-full">
            <h2 className="text-2xl font-bold mb-6">User Management</h2>
            
            {/* Filters */}
            <div className="flex flex-wrap space-x-4 mb-6 w-full">
                {/* Search Input */}
                <input
                    type="text"
                    placeholder="Search users..."
                    onChange={(e) => handleSearch(e.target.value)}
                    className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
                
                {/* Organization Filter */}
                <select
                    value={organizationFilter}
                    onChange={(e) => { setOrganizationFilter(e.target.value); setCurrentPage(1); }}
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
                >
                    <option value="">All Organisations</option>
                    {organizationOptions.map(org => (
                        <option key={org} value={org}>{org}</option>
                    ))}
                </select>
                
                {/* Domain Filter */}
                <select
                    value={domainFilter}
                    onChange={(e) => { setDomainFilter(e.target.value); setCurrentPage(1); }}
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
                >
                    <option value="">All Domains</option>
                    {domainOptions.map(domain => (
                        <option key={domain} value={domain}>{domain}</option>
                    ))}
                </select>
                
                {/* Create User Button */}
                <button
                    onClick={() => openModal()}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
                >
                    Create User
                </button>
            </div>

            {/* Error Message */}
            {error && (
                <div className="text-red-500 mb-4 flex items-center">
                    <span>{error}</span>
                    <button
                        onClick={fetchData}
                        className="ml-4 px-3 py-1 bg-red-200 text-red-700 rounded"
                    >
                        Retry
                    </button>
                </div>
            )}

            {/* Loading Indicator */}
            {loading ? (
                <div className="text-center py-8">
                    <span className="text-gray-500">Loading users...</span>
                </div>
            ) : (
                <>
                    {/* User List */}
                    <UserList
                        users={paginatedUsers}
                        onEditUser={openModal}
                        onToggleChange={handleToggleChange}
                        onSort={handleSort}
                        sortField={sortField}
                        sortOrder={sortOrder}
                    />
                    
                    {/* Pagination Controls */}
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="px-4 py-2 mr-2 bg-gray-200 rounded disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span className="px-4 py-2">{currentPage}</span>
                        <button
                            onClick={() => setCurrentPage(prev => prev + 1)}
                            disabled={currentPage * itemsPerPage >= sortedUsers.length}
                            className="px-4 py-2 ml-2 bg-gray-200 rounded disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {/* User Modal */}
            <UserModal
                isOpen={isModalOpen}
                onClose={closeModal}
                user={selectedUser}
                onFormSubmit={handleFormSubmit}
                onUpdateUser={handleUpdateUser}
            />
        </div>
    );
};

export default UsersManagement;
